@import url('https://rsms.me/inter/inter.css');
body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-kerning: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  /* With a sticky header requirement for member details in v2, we want
  to control which elements are scrollable
  The root element should not be scrollable but children should be */
  overflow: hidden;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
  overflow: "hidden";
}
