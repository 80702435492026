.base-date-picker {
  width: 100%;
  border: 1px solid red;
  display: block;
  height: 50px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #00b7c4;
  border-radius: 4px;
  padding: clamp(20px, 2%, 21px);
}
